import type { PhoneNumber } from 'libphonenumber-js'
import {
  AsYouType,
  isValidNumber as _isValidNumber,
  parse,
  format,
  parsePhoneNumber,
  getNumberType,
} from 'libphonenumber-js'
import LRU from 'lru-cache'

const cachedFormattedNumbers = new LRU<string, string>({ max: 2000 })

export const toE164 = (number: string) => {
  const parsed = parse(number, 'US')
  return format(parsed, 'E.164') || number
}

export const isNationalNumber = (number: string | PhoneNumber): boolean => {
  const phoneNumber = typeof number === 'string' ? parsePhoneNumber(number, 'US') : number
  return phoneNumber.country === 'US' || phoneNumber.country === 'CA'
}

export const isValidNumber = (number: string) =>
  !!number && _isValidNumber(toE164(number) ?? '')

export const formatted = (number: string, substitute?: string) => {
  let formatted = cachedFormattedNumbers.get(number)
  if (!formatted) {
    try {
      const phoneNumber = parsePhoneNumber(number, 'US')
      if (isNationalNumber(phoneNumber)) {
        formatted = phoneNumber.formatNational({ v2: true })
      } else {
        formatted = phoneNumber.formatInternational({ v2: true })
      }
    } catch (e) {
      formatted = number
    }
    cachedFormattedNumbers.set(number, formatted)
  }
  if (substitute) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    const digits = substitute.split('').map((c) => characterToDigit[c.toLowerCase()])
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const regex = digits.reduce((final, d) => {
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      final += `${d}(\-|\s|\(|\))*`
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return final
    }, '')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    let match: any = formatted.match(new RegExp(regex, 'g'))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    if (match && match.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      match = match[0]
      let newString = `${match}`
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      for (let j = 0, i = 0; j < match.length; j++) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        if ('0123456789'.indexOf(match[j]) !== -1) {
          newString =
            newString.substring(0, j) + String(substitute[i]) + newString.substring(j + 1)
          i++
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      formatted = formatted.replace(match, newString)
    }
  }
  return formatted || number
}

/**
 * Partially format a phone number.
 *
 * This is meant to be used as a phone number is being input. It will discard
 * invalid phone number characters and properly format a phone number as it is
 * being written.
 *
 * If the input is completely invalid, an empty string is returned.
 */
export const formatPartial = (input: string, countryCode?: PhoneNumber['country']) => {
  return new AsYouType(countryCode ?? 'US').input(input)
}

const areaCodeOf = (number: string | undefined) => {
  if (!number) {
    return undefined
  }
  const parsedNumber = parse(number)
  return parsedNumber.phone?.substr(0, 3)
}

const Types = {
  TollFree: 'Toll-Free',
  US: 'US',
  CA: 'CA',
} as const

/**
 * @deprecated Don't use this. Create a new function that uses libphonenumber-js instead.
 */
export const typeForNumber = (number: string | undefined) => {
  if (!number) {
    return undefined
  }
  const areaCode = areaCodeOf(number)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const info = areaCode ? codes[areaCode] : null
  if (!info) {
    return undefined
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  if (info.tollFree) {
    return Types.TollFree
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return info.country === 'CA' ? Types.CA : Types.US
}

export const countryForAreaCode = (areaCode) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  const info = codes[areaCode]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return info ? info.country : undefined
}

export const isInternational = (phoneNumber: string) => {
  try {
    const number = parsePhoneNumber(phoneNumber)
    return number.country !== 'CA' && number.country !== 'US'
  } catch {
    return false
  }
}

export const isUSPhoneNumber = (phoneNumber: string) => {
  try {
    const number = parsePhoneNumber(phoneNumber)
    return number.country === 'US'
  } catch {
    return false
  }
}

export const isCanadianPhoneNumber = (phoneNumber: string) => {
  try {
    const number = parsePhoneNumber(phoneNumber)
    return number.country === 'CA'
  } catch {
    return false
  }
}

export const isTollFreePhoneNumber = (phoneNumber: string) => {
  try {
    const number = parse(phoneNumber)
    const type = getNumberType(number)

    return type === 'TOLL_FREE'
  } catch {
    return false
  }
}

const characterToDigit = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  0: 0,
  a: 2,
  b: 2,
  c: 2,
  d: 3,
  e: 3,
  f: 3,
  g: 4,
  h: 4,
  i: 4,
  j: 5,
  k: 5,
  l: 5,
  m: 6,
  n: 6,
  o: 6,
  p: 7,
  q: 7,
  r: 7,
  s: 7,
  t: 8,
  u: 8,
  v: 8,
  w: 9,
  x: 9,
  y: 9,
  z: 9,
}

const codes = {
  // Canada
  204: {
    latitude: 51.203033636364,
    longitude: -98.729934545455,
    country: 'CA',
  },
  226: {
    latitude: 43.233831176471,
    longitude: -81.230922352941,
    country: 'CA',
  },
  236: {
    latitude: 50.432725294118,
    longitude: -121.51536941176,
    country: 'CA',
  },
  249: {
    latitude: 46.041822857143,
    longitude: -80.003286428571,
    country: 'CA',
  },
  250: {
    latitude: 50.837259090909,
    longitude: -121.84152,
    country: 'CA',
  },
  289: {
    latitude: 43.5654075,
    longitude: -79.333166,
    country: 'CA',
  },
  306: {
    latitude: 51.399736363636,
    longitude: -105.68861454545,
    country: 'CA',
  },
  343: {
    latitude: 44.955182142857,
    longitude: -76.08718,
    country: 'CA',
  },
  365: {
    latitude: 43.575115,
    longitude: -79.3496,
    country: 'CA',
  },
  403: {
    latitude: 51.2093535,
    longitude: -113.591395,
    country: 'CA',
  },
  416: {
    latitude: 43.459085,
    longitude: -79.70173,
    country: 'CA',
  },
  418: {
    latitude: 47.215538085106,
    longitude: -71.384436170213,
    country: 'CA',
  },
  431: {
    latitude: 51.203033636364,
    longitude: -98.729934545455,
    country: 'CA',
  },
  437: {
    latitude: 43.70011,
    longitude: -79.4163,
    country: 'CA',
  },
  438: {
    latitude: 45.5546,
    longitude: -73.88272,
    country: 'CA',
  },
  450: {
    latitude: 45.789232765957,
    longitude: -73.216014255319,
    country: 'CA',
  },
  506: {
    latitude: 46.566092222222,
    longitude: -66.060475555556,
    country: 'CA',
  },
  514: {
    latitude: 46.126976,
    longitude: -73.355944,
    country: 'CA',
  },
  519: {
    latitude: 43.2104985,
    longitude: -81.1431145,
    country: 'CA',
  },
  579: {
    latitude: 45.756958648649,
    longitude: -73.27781027027,
    country: 'CA',
  },
  581: {
    latitude: 47.26140744186,
    longitude: -71.35332372093,
    country: 'CA',
  },
  587: {
    latitude: 52.688353636364,
    longitude: -113.70208954545,
    country: 'CA',
  },
  604: {
    latitude: 49.30576625,
    longitude: -122.98475625,
    country: 'CA',
  },
  613: {
    latitude: 44.936384705882,
    longitude: -76.402184705882,
    country: 'CA',
  },
  639: {
    latitude: 51.636756,
    longitude: -106.001166,
    country: 'CA',
  },
  647: {
    latitude: 43.70011,
    longitude: -79.4163,
    country: 'CA',
  },
  705: {
    latitude: 45.7227065,
    longitude: -80.313261,
    country: 'CA',
  },
  709: {
    latitude: 48.949732857143,
    longitude: -55.959877142857,
    country: 'CA',
  },
  778: {
    latitude: 50.337179795918,
    longitude: -122.21482163265,
    country: 'CA',
  },
  780: {
    latitude: 53.9300256,
    longitude: -113.8699808,
    country: 'CA',
  },
  782: {
    latitude: 45.0743675,
    longitude: -63.865825,
    country: 'CA',
  },
  807: {
    latitude: 47.495825,
    longitude: -88.84699,
    country: 'CA',
  },
  819: {
    latitude: 46.339735,
    longitude: -73.465640277778,
    country: 'CA',
  },
  825: {
    latitude: 53.548798,
    longitude: -113.321158,
    country: 'CA',
  },
  867: {
    latitude: 62.30636,
    longitude: -105.97452333333,
    country: 'CA',
  },
  873: {
    latitude: 46.469091363636,
    longitude: -74.104794090909,
    country: 'CA',
  },
  902: {
    latitude: 45.441521666667,
    longitude: -63.155714166667,
    country: 'CA',
  },
  905: {
    latitude: 43.5654075,
    longitude: -79.333166,
    country: 'CA',
  },

  // United States
  201: {
    latitude: 40.83885,
    longitude: -74.045678125,
    country: 'US',
  },
  202: {
    latitude: 38.89511,
    longitude: -77.03637,
    country: 'US',
  },
  203: {
    latitude: 41.291798125,
    longitude: -73.122453125,
    country: 'US',
  },
  205: {
    latitude: 33.427671111111,
    longitude: -86.886473333333,
    country: 'US',
  },
  206: {
    latitude: 47.564027142857,
    longitude: -122.34897571429,
    country: 'US',
  },
  207: {
    latitude: 44.000005,
    longitude: -69.987965,
    country: 'US',
  },
  208: {
    latitude: 44.415674,
    longitude: -115.558462,
    country: 'US',
  },
  209: {
    latitude: 37.604049,
    longitude: -120.999087,
    country: 'US',
  },
  210: {
    latitude: 29.42412,
    longitude: -98.49363,
    country: 'US',
  },
  212: {
    latitude: 40.71427,
    longitude: -74.00597,
    country: 'US',
  },
  213: {
    latitude: 34.05223,
    longitude: -118.24368,
    country: 'US',
  },
  214: {
    latitude: 32.863500833333,
    longitude: -96.837767916667,
    country: 'US',
  },
  215: {
    latitude: 40.05372,
    longitude: -74.99628,
    country: 'US',
  },
  216: {
    latitude: 41.4749,
    longitude: -81.616895454545,
    country: 'US',
  },
  217: {
    latitude: 39.917895714286,
    longitude: -88.895028571429,
    country: 'US',
  },
  218: {
    latitude: 46.29681,
    longitude: -94.05581,
    country: 'US',
  },
  219: {
    latitude: 41.566263333333,
    longitude: -87.2636,
    country: 'US',
  },
  220: {
    latitude: 39.74919875,
    longitude: -82.66145,
    country: 'US',
  },
  224: {
    latitude: 42.14730875,
    longitude: -87.947605625,
    country: 'US',
  },
  225: {
    latitude: 30.45075,
    longitude: -91.15455,
    country: 'US',
  },
  228: {
    latitude: 30.388626666667,
    longitude: -88.845213333333,
    country: 'US',
  },
  229: {
    latitude: 31.205925,
    longitude: -83.71803,
    country: 'US',
  },
  231: {
    latitude: 43.02465,
    longitude: -85.181773333333,
    country: 'US',
  },
  234: {
    latitude: 41.063504444444,
    longitude: -81.314626111111,
    country: 'US',
  },
  239: {
    latitude: 26.456114285714,
    longitude: -81.800608571429,
    country: 'US',
  },
  240: {
    latitude: 39.047505,
    longitude: -77.121279285714,
    country: 'US',
  },
  248: {
    latitude: 42.543724615385,
    longitude: -83.262428461538,
    country: 'US',
  },
  251: {
    latitude: 30.71658,
    longitude: -88.06097,
    country: 'US',
  },
  252: {
    latitude: 35.42039,
    longitude: -77.43325,
    country: 'US',
  },
  253: {
    latitude: 47.228641,
    longitude: -122.365951,
    country: 'US',
  },
  254: {
    latitude: 31.204726,
    longitude: -97.579188,
    country: 'US',
  },
  256: {
    latitude: 34.418243333333,
    longitude: -86.638881666667,
    country: 'US',
  },
  260: {
    latitude: 41.1306,
    longitude: -85.12886,
    country: 'US',
  },
  262: {
    latitude: 42.991375,
    longitude: -88.039892,
    country: 'US',
  },
  267: {
    latitude: 40.05372,
    longitude: -74.99628,
    country: 'US',
  },
  269: {
    latitude: 42.270053333333,
    longitude: -85.448463333333,
    country: 'US',
  },
  270: {
    latitude: 37.44057,
    longitude: -87.006631428571,
    country: 'US',
  },
  272: {
    latitude: 41.238118,
    longitude: -76.10365,
    country: 'US',
  },
  276: {
    latitude: 37.55376,
    longitude: -77.46026,
    country: 'US',
  },
  281: {
    latitude: 29.709178888889,
    longitude: -95.300398888889,
    country: 'US',
  },
  301: {
    latitude: 39.047505,
    longitude: -77.121279285714,
    country: 'US',
  },
  302: {
    latitude: 39.52928,
    longitude: -75.606873333333,
    country: 'US',
  },
  303: {
    latitude: 39.77755625,
    longitude: -105.00266666667,
    country: 'US',
  },
  304: {
    latitude: 39.361145,
    longitude: -81.162093333333,
    country: 'US',
  },
  305: {
    latitude: 25.740417037037,
    longitude: -80.35898037037,
    country: 'US',
  },
  307: {
    latitude: 42.4022675,
    longitude: -105.5566625,
    country: 'US',
  },
  308: {
    latitude: 40.91612,
    longitude: -99.396303333333,
    country: 'US',
  },
  309: {
    latitude: 40.93229,
    longitude: -89.855943333333,
    country: 'US',
  },
  310: {
    latitude: 33.899128125,
    longitude: -118.352904375,
    country: 'US',
  },
  312: {
    latitude: 41.88475,
    longitude: -88.20396,
    country: 'US',
  },
  313: {
    latitude: 42.312228888889,
    longitude: -83.201191111111,
    country: 'US',
  },
  314: {
    latitude: 38.649496666667,
    longitude: -90.323053333333,
    country: 'US',
  },
  315: {
    latitude: 43.253676,
    longitude: -75.862524,
    country: 'US',
  },
  316: {
    latitude: 37.69224,
    longitude: -97.33754,
    country: 'US',
  },
  317: {
    latitude: 39.866706666667,
    longitude: -86.071743333333,
    country: 'US',
  },
  318: {
    latitude: 32.278824,
    longitude: -92.942602,
    country: 'US',
  },
  319: {
    latitude: 41.921948333333,
    longitude: -91.778878333333,
    country: 'US',
  },
  320: {
    latitude: 45.5608,
    longitude: -94.16249,
    country: 'US',
  },
  321: {
    latitude: 28.3756865,
    longitude: -81.1535335,
    country: 'US',
  },
  323: {
    latitude: 33.987178181818,
    longitude: -118.21200545455,
    country: 'US',
  },
  325: {
    latitude: 31.956255,
    longitude: -100.08509,
    country: 'US',
  },
  330: {
    latitude: 41.063504444444,
    longitude: -81.314626111111,
    country: 'US',
  },
  331: {
    latitude: 41.8771732,
    longitude: -88.0948236,
    country: 'US',
  },
  334: {
    latitude: 32.187855,
    longitude: -85.86078875,
    country: 'US',
  },
  336: {
    latitude: 35.986358,
    longitude: -79.858592,
    country: 'US',
  },
  337: {
    latitude: 30.242168,
    longitude: -92.500372,
    country: 'US',
  },
  339: {
    latitude: 42.389215652174,
    longitude: -71.100365652174,
    country: 'US',
  },
  346: {
    latitude: 29.709178888889,
    longitude: -95.300398888889,
    country: 'US',
  },
  347: {
    latitude: 40.691608,
    longitude: -73.959668,
    country: 'US',
  },
  351: {
    latitude: 42.603682857143,
    longitude: -71.226208571429,
    country: 'US',
  },
  352: {
    latitude: 28.372005,
    longitude: -81.7805925,
    country: 'US',
  },
  360: {
    latitude: 47.411182222222,
    longitude: -122.57029111111,
    country: 'US',
  },
  361: {
    latitude: 28.040573333333,
    longitude: -97.418696666667,
    country: 'US',
  },
  364: {
    latitude: 37.44057,
    longitude: -87.006631428571,
    country: 'US',
  },
  385: {
    latitude: 40.653856428571,
    longitude: -111.88019214286,
    country: 'US',
  },
  386: {
    latitude: 29.167798571429,
    longitude: -81.110851428571,
    country: 'US',
  },
  401: {
    latitude: 41.773583,
    longitude: -71.418123,
    country: 'US',
  },
  402: {
    latitude: 41.238971428571,
    longitude: -96.881062857143,
    country: 'US',
  },
  404: {
    latitude: 33.734668888889,
    longitude: -84.372366666667,
    country: 'US',
  },
  405: {
    latitude: 35.504228,
    longitude: -97.413675,
    country: 'US',
  },
  406: {
    latitude: 46.40531,
    longitude: -111.567485,
    country: 'US',
  },
  407: {
    latitude: 28.393690666667,
    longitude: -81.308249333333,
    country: 'US',
  },
  408: {
    latitude: 37.267165555556,
    longitude: -121.88483222222,
    country: 'US',
  },
  409: {
    latitude: 29.66411,
    longitude: -94.436145,
    country: 'US',
  },
  410: {
    latitude: 39.249978709677,
    longitude: -76.587990645161,
    country: 'US',
  },
  412: {
    latitude: 40.399266666667,
    longitude: -79.919527777778,
    country: 'US',
  },
  413: {
    latitude: 42.19644625,
    longitude: -72.713425,
    country: 'US',
  },
  414: {
    latitude: 42.946165714286,
    longitude: -87.950007142857,
    country: 'US',
  },
  415: {
    latitude: 37.927565,
    longitude: -122.517545,
    country: 'US',
  },
  417: {
    latitude: 37.14978,
    longitude: -93.90576,
    country: 'US',
  },
  419: {
    latitude: 41.128782857143,
    longitude: -83.21476,
    country: 'US',
  },
  423: {
    latitude: 35.841677142857,
    longitude: -83.68173,
    country: 'US',
  },
  424: {
    latitude: 33.899128125,
    longitude: -118.352904375,
    country: 'US',
  },
  425: {
    latitude: 47.733239285714,
    longitude: -121.86407785714,
    country: 'US',
  },
  432: {
    latitude: 32.030033333333,
    longitude: -102.01847,
    country: 'US',
  },
  434: {
    latitude: 37.339493333333,
    longitude: -79.016593333333,
    country: 'US',
  },
  435: {
    latitude: 39.270445,
    longitude: -112.68724,
    country: 'US',
  },
  440: {
    latitude: 41.479659333333,
    longitude: -81.697331333333,
    country: 'US',
  },
  442: {
    latitude: 33.669568095238,
    longitude: -116.75811238095,
    country: 'US',
  },
  443: {
    latitude: 39.249978709677,
    longitude: -76.587990645161,
    country: 'US',
  },
  458: {
    latitude: 43.77279625,
    longitude: -123.19441625,
    country: 'US',
  },
  469: {
    latitude: 32.869057826087,
    longitude: -96.826015652174,
    country: 'US',
  },
  478: {
    latitude: 32.730835,
    longitude: -83.61615,
    country: 'US',
  },
  479: {
    latitude: 35.849136,
    longitude: -93.987424,
    country: 'US',
  },
  480: {
    latitude: 33.431751428571,
    longitude: -111.79106428571,
    country: 'US',
  },
  484: {
    latitude: 40.251023,
    longitude: -75.457804,
    country: 'US',
  },
  501: {
    latitude: 34.768187142857,
    longitude: -92.421822857143,
    country: 'US',
  },
  502: {
    latitude: 38.227575,
    longitude: -85.316345,
    country: 'US',
  },
  503: {
    latitude: 45.347859333333,
    longitude: -122.79654266667,
    country: 'US',
  },
  504: {
    latitude: 29.941272857143,
    longitude: -90.092177142857,
    country: 'US',
  },
  505: {
    latitude: 35.545173333333,
    longitude: -107.14879333333,
    country: 'US',
  },
  507: {
    latitude: 44.048388333333,
    longitude: -92.93543,
    country: 'US',
  },
  508: {
    latitude: 42.006992727273,
    longitude: -71.224194545455,
    country: 'US',
  },
  509: {
    latitude: 46.765124444444,
    longitude: -118.73412111111,
    country: 'US',
  },
  510: {
    latitude: 37.43248,
    longitude: -121.80745230769,
    country: 'US',
  },
  512: {
    latitude: 30.359314,
    longitude: -97.772174,
    country: 'US',
  },
  513: {
    latitude: 39.349302857143,
    longitude: -84.498834285714,
    country: 'US',
  },
  515: {
    latitude: 41.840495,
    longitude: -93.75476,
    country: 'US',
  },
  516: {
    latitude: 40.701854736842,
    longitude: -73.598140526316,
    country: 'US',
  },
  517: {
    latitude: 42.487028333333,
    longitude: -84.137551666667,
    country: 'US',
  },
  518: {
    latitude: 42.819186,
    longitude: -73.833068,
    country: 'US',
  },
  520: {
    latitude: 32.118015714286,
    longitude: -110.97648428571,
    country: 'US',
  },
  530: {
    latitude: 39.338822857143,
    longitude: -121.56659428571,
    country: 'US',
  },
  531: {
    latitude: 41.238971428571,
    longitude: -96.881062857143,
    country: 'US',
  },
  539: {
    latitude: 36.1754425,
    longitude: -95.783525,
    country: 'US',
  },
  540: {
    latitude: 37.829541428571,
    longitude: -79.504057142857,
    country: 'US',
  },
  541: {
    latitude: 43.77279625,
    longitude: -123.19441625,
    country: 'US',
  },
  551: {
    latitude: 40.83885,
    longitude: -74.045678125,
    country: 'US',
  },
  559: {
    latitude: 36.50766,
    longitude: -119.5360325,
    country: 'US',
  },
  561: {
    latitude: 26.555793333333,
    longitude: -80.135440833333,
    country: 'US',
  },
  562: {
    latitude: 33.897952857143,
    longitude: -118.08427714286,
    country: 'US',
  },
  563: {
    latitude: 41.763524,
    longitude: -90.59797,
    country: 'US',
  },
  567: {
    latitude: 41.128782857143,
    longitude: -83.21476,
    country: 'US',
  },
  570: {
    latitude: 41.238118,
    longitude: -76.10365,
    country: 'US',
  },
  571: {
    latitude: 38.8324725,
    longitude: -77.270965,
    country: 'US',
  },
  573: {
    latitude: 38.278096666667,
    longitude: -91.341913333333,
    country: 'US',
  },
  574: {
    latitude: 41.672602,
    longitude: -86.066116,
    country: 'US',
  },
  575: {
    latitude: 33.022383333333,
    longitude: -104.63893833333,
    country: 'US',
  },
  580: {
    latitude: 35.170991666667,
    longitude: -97.964955,
    country: 'US',
  },
  585: {
    latitude: 43.171913333333,
    longitude: -77.581943333333,
    country: 'US',
  },
  586: {
    latitude: 42.539758571429,
    longitude: -82.970317142857,
    country: 'US',
  },
  601: {
    latitude: 32.197054285714,
    longitude: -89.961072857143,
    country: 'US',
  },
  602: {
    latitude: 33.44838,
    longitude: -112.07404,
    country: 'US',
  },
  603: {
    latitude: 43.04893875,
    longitude: -71.33472,
    country: 'US',
  },
  605: {
    latitude: 44.472588,
    longitude: -99.176772,
    country: 'US',
  },
  606: {
    latitude: 38.47841,
    longitude: -82.63794,
    country: 'US',
  },
  607: {
    latitude: 42.209706666667,
    longitude: -76.407436666667,
    country: 'US',
  },
  608: {
    latitude: 43.042495,
    longitude: -89.563915,
    country: 'US',
  },
  609: {
    latitude: 39.9697625,
    longitude: -74.7087,
    country: 'US',
  },
  610: {
    latitude: 40.251023,
    longitude: -75.457804,
    country: 'US',
  },
  612: {
    latitude: 44.9708,
    longitude: -93.32084,
    country: 'US',
  },
  614: {
    latitude: 40.008745,
    longitude: -83.0058775,
    country: 'US',
  },
  615: {
    latitude: 36.10672375,
    longitude: -86.5878425,
    country: 'US',
  },
  616: {
    latitude: 42.915765,
    longitude: -85.73082,
    country: 'US',
  },
  617: {
    latitude: 42.348581818182,
    longitude: -71.100127272727,
    country: 'US',
  },
  618: {
    latitude: 38.56595375,
    longitude: -89.9325675,
    country: 'US',
  },
  619: {
    latitude: 32.720583333333,
    longitude: -117.04660416667,
    country: 'US',
  },
  620: {
    latitude: 38.0473075,
    longitude: -98.7502925,
    country: 'US',
  },
  623: {
    latitude: 33.5849,
    longitude: -112.29812166667,
    country: 'US',
  },
  626: {
    latitude: 34.0783752,
    longitude: -118.0051808,
    country: 'US',
  },
  628: {
    latitude: 37.927565,
    longitude: -122.517545,
    country: 'US',
  },
  629: {
    latitude: 36.10672375,
    longitude: -86.5878425,
    country: 'US',
  },
  630: {
    latitude: 41.8771732,
    longitude: -88.0948236,
    country: 'US',
  },
  631: {
    latitude: 40.79404826087,
    longitude: -73.20643,
    country: 'US',
  },
  636: {
    latitude: 38.705978333333,
    longitude: -90.598965,
    country: 'US',
  },
  641: {
    latitude: 42.074336666667,
    longitude: -92.840106666667,
    country: 'US',
  },
  646: {
    latitude: 40.71427,
    longitude: -74.00597,
    country: 'US',
  },
  650: {
    latitude: 37.53483375,
    longitude: -122.284655625,
    country: 'US',
  },
  651: {
    latitude: 44.912425714286,
    longitude: -93.084087857143,
    country: 'US',
  },
  657: {
    latitude: 33.804700666667,
    longitude: -117.92452266667,
    country: 'US',
  },
  660: {
    latitude: 38.70446,
    longitude: -93.22826,
    country: 'US',
  },
  661: {
    latitude: 35.117865714286,
    longitude: -118.77457857143,
    country: 'US',
  },
  662: {
    latitude: 34.109314285714,
    longitude: -89.632332857143,
    country: 'US',
  },
  667: {
    latitude: 39.249978709677,
    longitude: -76.587990645161,
    country: 'US',
  },
  669: {
    latitude: 37.267165555556,
    longitude: -121.88483222222,
    country: 'US',
  },
  678: {
    latitude: 33.814474782609,
    longitude: -84.360855217391,
    country: 'US',
  },
  681: {
    latitude: 39.361145,
    longitude: -81.162093333333,
    country: 'US',
  },
  682: {
    latitude: 32.759575333333,
    longitude: -97.223403333333,
    country: 'US',
  },
  701: {
    latitude: 47.4602675,
    longitude: -99.003305,
    country: 'US',
  },
  702: {
    latitude: 36.140527142857,
    longitude: -115.11436428571,
    country: 'US',
  },
  703: {
    latitude: 38.8324725,
    longitude: -77.270965,
    country: 'US',
  },
  704: {
    latitude: 35.367685555556,
    longitude: -80.747737777778,
    country: 'US',
  },
  706: {
    latitude: 33.639484285714,
    longitude: -83.94034,
    country: 'US',
  },
  707: {
    latitude: 38.514229090909,
    longitude: -122.52735727273,
    country: 'US',
  },
  708: {
    latitude: 41.705734761905,
    longitude: -87.72693047619,
    country: 'US',
  },
  712: {
    latitude: 41.880965,
    longitude: -96.13057,
    country: 'US',
  },
  713: {
    latitude: 29.709178888889,
    longitude: -95.300398888889,
    country: 'US',
  },
  714: {
    latitude: 33.804700666667,
    longitude: -117.92452266667,
    country: 'US',
  },
  715: {
    latitude: 45.25371,
    longitude: -90.7018125,
    country: 'US',
  },
  716: {
    latitude: 42.87564,
    longitude: -78.89547375,
    country: 'US',
  },
  717: {
    latitude: 40.1537775,
    longitude: -76.582255,
    country: 'US',
  },
  718: {
    latitude: 40.691608,
    longitude: -73.959668,
    country: 'US',
  },
  719: {
    latitude: 38.64372,
    longitude: -104.75283333333,
    country: 'US',
  },
  720: {
    latitude: 39.77755625,
    longitude: -105.00266666667,
    country: 'US',
  },
  724: {
    latitude: 40.565275,
    longitude: -79.9919975,
    country: 'US',
  },
  725: {
    latitude: 36.140527142857,
    longitude: -115.11436428571,
    country: 'US',
  },
  727: {
    latitude: 28.006899,
    longitude: -82.736329,
    country: 'US',
  },
  731: {
    latitude: 35.61452,
    longitude: -88.81395,
    country: 'US',
  },
  732: {
    latitude: 40.420721428571,
    longitude: -74.323679285714,
    country: 'US',
  },
  734: {
    latitude: 42.251202727273,
    longitude: -83.413476363636,
    country: 'US',
  },
  737: {
    latitude: 30.359314,
    longitude: -97.772174,
    country: 'US',
  },
  740: {
    latitude: 39.74919875,
    longitude: -82.66145,
    country: 'US',
  },
  743: {
    latitude: 35.986358,
    longitude: -79.858592,
    country: 'US',
  },
  747: {
    latitude: 34.186115,
    longitude: -118.43554333333,
    country: 'US',
  },
  754: {
    latitude: 26.143138421053,
    longitude: -80.201925263158,
    country: 'US',
  },
  757: {
    latitude: 36.870171428571,
    longitude: -76.313514285714,
    country: 'US',
  },
  760: {
    latitude: 33.669568095238,
    longitude: -116.75811238095,
    country: 'US',
  },
  762: {
    latitude: 33.639484285714,
    longitude: -83.94034,
    country: 'US',
  },
  763: {
    latitude: 45.098514444444,
    longitude: -93.359148888889,
    country: 'US',
  },
  765: {
    latitude: 40.289168571429,
    longitude: -85.937961428571,
    country: 'US',
  },
  769: {
    latitude: 32.197054285714,
    longitude: -89.961072857143,
    country: 'US',
  },
  770: {
    latitude: 33.8336605,
    longitude: -84.3569235,
    country: 'US',
  },
  772: {
    latitude: 27.37194,
    longitude: -80.3461,
    country: 'US',
  },
  773: {
    latitude: 41.88475,
    longitude: -88.20396,
    country: 'US',
  },
  774: {
    latitude: 42.006992727273,
    longitude: -71.224194545455,
    country: 'US',
  },
  775: {
    latitude: 38.6091575,
    longitude: -118.82945,
    country: 'US',
  },
  779: {
    latitude: 42.050869166667,
    longitude: -88.6227275,
    country: 'US',
  },
  781: {
    latitude: 42.389215652174,
    longitude: -71.100365652174,
    country: 'US',
  },
  785: {
    latitude: 38.984614,
    longitude: -96.88463,
    country: 'US',
  },
  786: {
    latitude: 25.740417037037,
    longitude: -80.35898037037,
    country: 'US',
  },
  801: {
    latitude: 40.653856428571,
    longitude: -111.88019214286,
    country: 'US',
  },
  802: {
    latitude: 44.363525,
    longitude: -72.873175,
    country: 'US',
  },
  803: {
    latitude: 34.08893,
    longitude: -81.057868,
    country: 'US',
  },
  804: {
    latitude: 37.456984,
    longitude: -77.415816,
    country: 'US',
  },
  805: {
    latitude: 34.60204125,
    longitude: -119.696259375,
    country: 'US',
  },
  806: {
    latitude: 34.328216666667,
    longitude: -101.79777,
    country: 'US',
  },
  808: {
    latitude: 20.956035555556,
    longitude: -157.23792666667,
    country: 'US',
  },
  810: {
    latitude: 42.994286666667,
    longitude: -83.242903333333,
    country: 'US',
  },
  812: {
    latitude: 38.66688,
    longitude: -86.391257142857,
    country: 'US',
  },
  813: {
    latitude: 28.047438888889,
    longitude: -82.427858888889,
    country: 'US',
  },
  814: {
    latitude: 40.9420075,
    longitude: -78.8154425,
    country: 'US',
  },
  815: {
    latitude: 42.050869166667,
    longitude: -88.6227275,
    country: 'US',
  },
  816: {
    latitude: 39.104366,
    longitude: -94.500676,
    country: 'US',
  },
  817: {
    latitude: 32.759575333333,
    longitude: -97.223403333333,
    country: 'US',
  },
  818: {
    latitude: 34.186115,
    longitude: -118.43554333333,
    country: 'US',
  },
  828: {
    latitude: 35.66707,
    longitude: -81.94761,
    country: 'US',
  },
  830: {
    latitude: 29.478228,
    longitude: -99.325156,
    country: 'US',
  },
  831: {
    latitude: 36.758607142857,
    longitude: -121.77046571429,
    country: 'US',
  },
  832: {
    latitude: 29.709178888889,
    longitude: -95.300398888889,
    country: 'US',
  },
  843: {
    latitude: 33.0913225,
    longitude: -79.901955,
    country: 'US',
  },
  845: {
    latitude: 41.47292,
    longitude: -74.064123333333,
    country: 'US',
  },
  847: {
    latitude: 42.14730875,
    longitude: -87.947605625,
    country: 'US',
  },
  848: {
    latitude: 40.420721428571,
    longitude: -74.323679285714,
    country: 'US',
  },
  850: {
    latitude: 30.418668888889,
    longitude: -86.61346,
    country: 'US',
  },
  856: {
    latitude: 39.737218571429,
    longitude: -75.053785714286,
    country: 'US',
  },
  857: {
    latitude: 42.348581818182,
    longitude: -71.100127272727,
    country: 'US',
  },
  858: {
    latitude: 32.96282,
    longitude: -117.03586,
    country: 'US',
  },
  859: {
    latitude: 38.4547925,
    longitude: -84.4768825,
    country: 'US',
  },
  860: {
    latitude: 41.674009166667,
    longitude: -72.641041666667,
    country: 'US',
  },
  862: {
    latitude: 40.825112857143,
    longitude: -74.216403333333,
    country: 'US',
  },
  863: {
    latitude: 28.030855,
    longitude: -81.84133,
    country: 'US',
  },
  864: {
    latitude: 34.720858333333,
    longitude: -82.294563333333,
    country: 'US',
  },
  865: {
    latitude: 35.9344575,
    longitude: -84.10762,
    country: 'US',
  },
  870: {
    latitude: 34.654171666667,
    longitude: -91.682218333333,
    country: 'US',
  },
  878: {
    latitude: 40.459707,
    longitude: -79.962276,
    country: 'US',
  },
  901: {
    latitude: 35.120715,
    longitude: -89.849395,
    country: 'US',
  },
  903: {
    latitude: 33.012011111111,
    longitude: -95.526376666667,
    country: 'US',
  },
  904: {
    latitude: 30.252276666667,
    longitude: -81.605646666667,
    country: 'US',
  },
  907: {
    latitude: 60.268353333333,
    longitude: -141.136315,
    country: 'US',
  },
  908: {
    latitude: 40.662897272727,
    longitude: -74.289773636364,
    country: 'US',
  },
  909: {
    latitude: 34.074295,
    longitude: -117.54055722222,
    country: 'US',
  },
  910: {
    latitude: 34.757956,
    longitude: -78.453958,
    country: 'US',
  },
  912: {
    latitude: 32.126403333333,
    longitude: -81.492983333333,
    country: 'US',
  },
  913: {
    latitude: 39.0303175,
    longitude: -94.718015,
    country: 'US',
  },
  914: {
    latitude: 41.026625,
    longitude: -73.8051675,
    country: 'US',
  },
  915: {
    latitude: 31.70664,
    longitude: -106.39512,
    country: 'US',
  },
  916: {
    latitude: 38.540042352941,
    longitude: -121.39831588235,
    country: 'US',
  },
  917: {
    latitude: 40.695385,
    longitude: -73.967385,
    country: 'US',
  },
  918: {
    latitude: 36.1754425,
    longitude: -95.783525,
    country: 'US',
  },
  919: {
    latitude: 35.726142857143,
    longitude: -78.77469,
    country: 'US',
  },
  920: {
    latitude: 43.948076,
    longitude: -88.281434,
    country: 'US',
  },
  925: {
    latitude: 37.891776666667,
    longitude: -121.935052,
    country: 'US',
  },
  928: {
    latitude: 34.3190475,
    longitude: -113.5519425,
    country: 'US',
  },
  929: {
    latitude: 40.691608,
    longitude: -73.959668,
    country: 'US',
  },
  930: {
    latitude: 38.66688,
    longitude: -86.391257142857,
    country: 'US',
  },
  931: {
    latitude: 36.10256,
    longitude: -86.632123333333,
    country: 'US',
  },
  936: {
    latitude: 30.99429,
    longitude: -95.097855,
    country: 'US',
  },
  937: {
    latitude: 39.854266923077,
    longitude: -84.115557692308,
    country: 'US',
  },
  940: {
    latitude: 33.391593333333,
    longitude: -97.54021,
    country: 'US',
  },
  941: {
    latitude: 27.202133333333,
    longitude: -82.344351666667,
    country: 'US',
  },
  947: {
    latitude: 42.543724615385,
    longitude: -83.262428461538,
    country: 'US',
  },
  949: {
    latitude: 33.573486153846,
    longitude: -117.73371615385,
    country: 'US',
  },
  951: {
    latitude: 33.819667,
    longitude: -117.258786,
    country: 'US',
  },
  952: {
    latitude: 44.838195,
    longitude: -93.4117575,
    country: 'US',
  },
  954: {
    latitude: 26.143138421053,
    longitude: -80.201925263158,
    country: 'US',
  },
  956: {
    latitude: 26.299599,
    longitude: -98.138061,
    country: 'US',
  },
  959: {
    latitude: 41.674009166667,
    longitude: -72.641041666667,
    country: 'US',
  },
  970: {
    latitude: 40.11755,
    longitude: -105.854795,
    country: 'US',
  },
  971: {
    latitude: 45.347859333333,
    longitude: -122.79654266667,
    country: 'US',
  },
  972: {
    latitude: 32.869057826087,
    longitude: -96.826015652174,
    country: 'US',
  },
  973: {
    latitude: 40.834617368421,
    longitude: -74.219415789474,
    country: 'US',
  },
  978: {
    latitude: 42.603682857143,
    longitude: -71.226208571429,
    country: 'US',
  },
  979: {
    latitude: 30.112066666667,
    longitude: -96.046253333333,
    country: 'US',
  },
  980: {
    latitude: 35.367685555556,
    longitude: -80.747737777778,
    country: 'US',
  },
  983: {
    latitude: 39.77755625,
    longitude: -105.00266666667,
    country: 'US',
  },
  984: {
    latitude: 35.726142857143,
    longitude: -78.77469,
    country: 'US',
  },
  985: {
    latitude: 29.979313333333,
    longitude: -90.32739,
    country: 'US',
  },
  989: {
    latitude: 43.52936,
    longitude: -84.16104,
    country: 'US',
  },
  854: {
    latitude: 33.0913225,
    longitude: -79.901955,
    country: 'US',
  },

  // Toll-Free
  800: {
    country: 'US',
    tollFree: true,
  },
  822: {
    country: 'US',
    tollFree: true,
  },
  833: {
    country: 'US',
    tollFree: true,
  },
  844: {
    country: 'US',
    tollFree: true,
  },
  855: {
    country: 'US',
    tollFree: true,
  },
  866: {
    country: 'US',
    tollFree: true,
  },
  877: {
    country: 'US',
    tollFree: true,
  },
  880: {
    country: 'US',
    tollFree: true,
  },
  887: {
    country: 'US',
    tollFree: true,
  },
  888: {
    country: 'US',
    tollFree: true,
  },
  889: {
    country: 'US',
    tollFree: true,
  },
}
