import { makeAutoObservable } from 'mobx'

import {
  getSingleFirstAndLastName,
  getInitials,
  isArrayOfStrings,
  parseDate,
} from '@src/lib'
import { assertIsNotUndefined } from '@src/lib/isNonNull'
import isTruthy from '@src/lib/isTruthy'
import objectId from '@src/lib/objectId'
import { formatted, toE164 } from '@src/lib/phone-number'
import type ContactStore from '@src/service/contact-store'
import type { VisibilityType } from '@src/service/transport/contacts'

import type { CodableContactItem, LocalCodableContactItem } from './ContactItemModel'
import ContactItemModel from './ContactItemModel'
import type { ContactTemplateItemModel } from './ContactTemplateItemModel'
import type { DecodableNote, EncodableNote } from './NoteModel'
import NoteModel from './NoteModel'
import type { Identity, IdentityPhone, Model } from './base'

export type Source = 'openphone' | 'device' | 'google-people' | 'csv' | 'other' | 'csv-v2'

export interface CodableContact {
  id: string
  source?: string
  sourceName: string | null
  userId: string | null
  firstName: string | null
  lastName: string | null
  company: string | null
  role: string | null
  location: string | null
  pictureUrl: string | null
  items: CodableContactItem[]
  sharedWith: string[]
  deletedAt: number | null
  createdAt: number | null
  updatedAt: number | null
  notes: DecodableNote[]
  orgId: string
  clientId?: string | null
  meta?: Record<string, unknown>
}

export interface DecodableContact extends CodableContact {
  sortName?: string
  local: boolean | null
  visibility: VisibilityType | null
  _phoneNumbers?: string[]
}

class ContactModel implements CodableContact, Model, Identity {
  id: string = objectId()
  visibility: VisibilityType | null = null
  source?: Source
  sourceName: string | null = null
  userId: string | null = null
  firstName: string | null = null
  lastName: string | null = null
  company: string | null = null
  role: string | null = null
  location: string | null = null
  pictureUrl: string | null = null
  pictureSymbol = undefined
  items: ContactItemModel[] = []
  sharedWith: string[] = []
  deletedAt: number | null = null
  createdAt: number | null = Date.now()
  updatedAt: number | null = Date.now()
  local: boolean | null = null
  notes: NoteModel[] = []
  orgId = ''
  clientId?: string | null = null

  constructor(
    private contactStore: ContactStore,
    attrs: Partial<DecodableContact> = {},
  ) {
    this.deserialize(attrs)

    makeAutoObservable(
      this,
      {
        hasPhoneNumber: false,
      },
      {
        autoBind: true,
      },
    )
  }

  get name(): string {
    if (!this.firstName && !this.lastName) {
      if (this.company) {
        return this.company
      }
      if (this.phoneNumbers.length > 0) {
        // assertIsNotUndefined(this.phoneNumbers[0])
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        return formatted(this.phoneNumbers[0].value)
      }
      if (this.emails.length > 0) {
        // assertIsNotUndefined(this.emails[0])
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        return this.emails[0].value
      }
      return 'Unnamed'
    }
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get fullName(): string {
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.firstName, this.lastName].find(isTruthy) ?? ''
  }

  get initials(): string {
    if (this.firstName || this.lastName) {
      const singleFirstNameAndLastName = getSingleFirstAndLastName(
        this.firstName,
        this.lastName,
      )
      return getInitials(singleFirstNameAndLastName)
    } else if (this.company) {
      return getInitials(this.company)
    }
    return ''
  }

  get phoneNumbers() {
    return this.items.filter((p) => p.type === 'phone-number' && p.value)
  }

  get emails() {
    return this.items.filter((p) => p.type === 'email' && p.value)
  }

  get phones(): IdentityPhone[] {
    return this.phoneNumbers.map((p) => ({
      id: null,
      name: p.name,
      symbol: null,
      number: String(p.value),
      isOffHours: null,
    }))
  }

  get emailAddresses(): string[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.emails.map((i) => i.value)
  }

  get job() {
    return [this.role, this.company].filter((x) => x).join(' at ')
  }

  get sortName() {
    return [
      this.firstName,
      this.lastName,
      this.company,
      ...this.phoneNumbers.map((i) => `~${i.value}`),
    ]
      .join('')
      .toLowerCase()
      .trim()
  }

  get isEmpty() {
    return (
      !this.firstName &&
      !this.lastName &&
      !this.company &&
      !this.role &&
      this.items.every((i) => i.isEmpty) &&
      this.notes.length === 0
    )
  }

  get totalMembersSharedWith() {
    return this.contactStore.getUniqueMemberCountInEntities(this.sharedWith)
  }

  get sortedNotes() {
    return (
      this.notes?.slice().sort((a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0)) ?? []
    )
  }

  get isAnonymous() {
    return this.local || !this.fullName
  }

  hasPhoneNumber(number: string) {
    return Boolean(
      this.items.find((i) => i.type === 'phone-number' && i.value === number),
    )
  }

  addItem(item: LocalCodableContactItem): ContactItemModel {
    const ci = new ContactItemModel(this.contactStore, this, item)
    this.items.push(ci)
    return ci
  }

  update(attrs: Partial<this> = {}) {
    Object.assign(this, attrs)

    return this.contactStore.update(this)
  }

  delete() {
    return this.contactStore.delete(this)
  }

  getOrCreateItemForTemplate(template: ContactTemplateItemModel) {
    let item = this.items.find((i) => i.templateKey === template.key && !i.deletedAt)
    if (!item) {
      item = new ContactItemModel(this.contactStore, this, {
        type: template.type,
        name: template.name,
        templateKey: template.key,
        isNew: true,
      })
      this.items.push(item)
    }
    return item
  }

  /**
   * Returns the list of tag values for the provided template id
   */
  getTagValues(templateId: string): string[] {
    return this.items
      .filter((item) => item.template?.id === templateId && !item.deletedAt)
      .flatMap((item) => (isArrayOfStrings(item.value) ? item.value : []))
  }

  deserialize(attrs: Partial<DecodableContact>) {
    if (attrs) {
      const {
        sortName: _sortName,
        meta: _meta,
        notes,
        items,
        deletedAt,
        updatedAt,
        createdAt,
        _phoneNumbers,
        ...json
      } = attrs
      Object.assign(this, json)

      this.notes =
        notes

          ?.filter((note) => !note.deletedAt)
          .map((note) => {
            const existingNote = this.notes.find((item) => item.id === note.id)

            if (existingNote) {
              return existingNote.deserialize(note as EncodableNote)
            } else {
              return new NoteModel(this.contactStore, this, note as EncodableNote)
            }
          }) ?? []

      this.items =
        items

          ?.filter((item) => !item.deletedAt)
          .map((item) => new ContactItemModel(this.contactStore, this, item)) ?? []

      this.deletedAt = deletedAt ? parseDate(deletedAt) : null

      this.updatedAt = updatedAt ? parseDate(updatedAt) : null

      this.createdAt = createdAt ? parseDate(createdAt) : null
    }
    return this
  }

  serialize(): DecodableContact {
    return {
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      id: this.id,
      items: this.items.map((i) => i.serialize()),
      lastName: this.lastName,
      local: this.local,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      source: this.source,
      sourceName: this.sourceName,
      updatedAt: this.updatedAt,
      userId: this.userId,
      visibility: this.visibility,
      sortName: this.sortName,
      orgId: this.orgId,
      clientId: this.clientId,
      _phoneNumbers: this.phoneNumbers
        .map((i) => toE164(i.value as string))
        .filter(isTruthy),
    }
  }

  toJSON(): CodableContact {
    return {
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      id: this.id,
      items: this.items.filter((i) => !i.isNew || i.value).map((i) => i.serialize()),
      lastName: this.lastName,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      source: this.source,
      sourceName: this.sourceName,
      updatedAt: this.updatedAt,
      userId: this.userId,
      orgId: this.orgId,
      clientId: this.clientId,
    }
  }

  tearDown() {
    this.items = []
  }
}

export default ContactModel
