import { createClient as createElectronClient } from '@openphone/desktop-client'
import { createRoot } from 'react-dom/client'
import { RelayEnvironmentProvider } from 'react-relay'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from './app'
import AppStore from './app/AppStore'
import { AppContext } from './app/context'
import { SentryManager } from './app/error'
import { platform, deviceId } from './config'
import { createWorkerQLRelayEnvironment } from './graphql/client'
import { extendDayJs } from './lib/date'
import log from './lib/log'
import './lib/polyfills'
import Service from './service'
import Worker from './service/worker?worker&url'

extendDayJs()

async function loadApp() {
  SentryManager.initialize()

  const client = createElectronClient() ?? null
  const desktopVersion = client?.version
  const relayEnvironment = await createWorkerQLRelayEnvironment({
    meta: {
      platform: platform || 'browser',
      desktopVersion,
      deviceId,
    },
  })

  const service = new Service(
    {
      workerName: Worker,
    },
    desktopVersion,
  )

  const prevOnError = window.onerror
  window.onerror = function (message: string | Event, ...args: any[]) {
    // Benign error that can be ignored https://stackoverflow.com/a/50387233
    if (message === 'ResizeObserver loop limit exceeded') {
      return
    }

    log.error(typeof message === 'string' ? message : 'Unknown Error', args)

    if (prevOnError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return prevOnError(message, ...args)
    }

    return false
  }

  await Promise.all([service.auth.init(), service.flags.waitUntilReady()])

  const router = createBrowserRouter([
    {
      path: '*',
      element: <App />,
    },
  ])

  const store = new AppStore(client, router, service)

  // FIXME: remove these session token query parameters after users have been
  // migrated from 3.15.7, see https://linear.app/openphone/issue/ENG-4864
  const historyManager = store.history
  const idToken = historyManager.consumeQueryParam('session_id_token')
  const refreshToken = historyManager.consumeQueryParam('session_refresh_token')

  if (idToken) {
    await service.storage.clearAll()
    await service.auth.setSession({
      idToken,
      refreshToken,
    })
    window.location.reload()
    return
  }

  const element = document.getElementById('root')

  if (!element) {
    throw new Error('Could not find root element')
  }

  const root = createRoot(element)

  root.render(
    <AppContext.Provider value={store}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </RelayEnvironmentProvider>
    </AppContext.Provider>,
  )
}

loadApp()
