/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { z } from 'zod'

import config from '@src/config'
import { toQueryString } from '@src/lib'
import type { Branded } from '@src/lib/brand'
import type {
  AcceptedReferral,
  CodablePhoneNumber,
  CodableBlocklist,
  CodablePresence,
  EncodableUser,
  ReferralCode,
  CodableUserSettings,
  SerializedMember,
  MemberModel,
  CodableOrganization,
  CodableDomain,
  CodableGroup,
  CodableUser,
} from '@src/service/model'
import type { CodableEntityPhoneNumber } from '@src/service/model/EntityPhoneNumberModel'
import type AlertAssociations from '@src/service/model/alert/AlertAssociations'
import type DecodableAlert from '@src/service/model/alert/DecodableAlert'

import type {
  AccountPortRequestGetAllResponse,
  AccountPortRequestUpsertRequest,
  AccountPortRequestUpsertResponse,
  AccountPortRequestSubmitRequest,
} from '..'

import type Transport from '.'
import type Paginated from './lib/Paginated'
import { HttpTransaction } from './transaction'

export type AccountConnectionType =
  | 'email'
  | 'google-oauth2'
  | 'Username-Password-Authentication'

interface AccountConnectionsResult {
  connections: AccountConnectionType[]
}

export interface Application {
  clientId: string
  description: string
  id: string
  logoUrl: string
  name: string
  verified: boolean
}

export interface AvailablePhoneNumber {
  phoneNumber: string
  formatted: string
  locality: string
  region: string
  country: string
}

export interface Invite {
  id: string
  email: string
  org: {
    name: string
    pictureUrl: string
    numMembers?: number
  }
  role: string
  sentBy: string
  token: string
  updatedAt: number
  createdAt: number
}

export type UserStatus = 'active' | 'inactive'

export type Membership = {
  id?: string
  directNumber?: string
  directNumberId?: string
  orgId?: string
  role?: RoleName
  title?: string
  email?: string
  firstName?: string
  lastName?: string
  pictureUrl?: string
  status?: UserStatus
  createdAt?: number
  updatedAt?: number
}

export type AvailabilityScheduleRange = {
  readonly start: string | null
  readonly end: string
}

export type NonNullAvailabilitySchedule = readonly AvailabilityScheduleRange[]
type NullAvailabilitySchedule = readonly null[]
export type AvailabilitySchedule = NonNullAvailabilitySchedule | NullAvailabilitySchedule
export function isNullAvailabilitySchedule(
  schedule: AvailabilitySchedule,
): schedule is NullAvailabilitySchedule {
  return schedule[0] === null
}

export interface AvailabilityHours {
  readonly enabled: boolean
  readonly schedule: AvailabilitySchedule
  readonly timezone: string | null
}

type ValidAvailabilityScheduleRange = Branded<
  AvailabilityScheduleRange,
  'ValidAvailabilityScheduleRange'
>
type ValidAvailabilitySchedule = readonly ValidAvailabilityScheduleRange[]

export type ValidAvailabilityHours = Omit<AvailabilityHours, 'schedule'> & {
  schedule: ValidAvailabilitySchedule
}
/**
 * Ensures that the user can't set an end time before the start time
 */
export function parseToValidAvailabilityScheduleRange({
  start,
  end,
}: AvailabilityScheduleRange): ValidAvailabilityScheduleRange {
  let potentiallyNewEnd = end
  if (start && Number(start) > Number(end)) {
    potentiallyNewEnd = start
  }
  return { start, end: potentiallyNewEnd } as ValidAvailabilityScheduleRange
}

export interface PhoneNumber {
  id?: string
  local?: boolean
  orgId: string
  groupId: string
  entityId: string
  entityType: string
  entityName: string
  number: string
  formattedNumber: string
  forward: string
  name: string
  mutedUntil: number
  voicemailId: string
  voicemailUrl: string
  awayVoicemailId: string
  awayVoicemailUrl: string
  availabilityHours: AvailabilityHours
  timeout: number
  createdAt: number
  updatedAt: number
  users: UserPhoneNumber[]
  settings?: PhoneNumberSettings
  symbol?: string
}

export interface PhoneNumberSettings {
  autoRecord?: boolean
  maxDial?: number
  callTranscription?: {
    enabled: boolean
  }
  international?: {
    enabled: boolean
  }
  transcription?: {
    languageCode?: string
    profanityFilter?: boolean
    enabled?: boolean
  }
  autoRespond?: {
    enabled?: boolean
    businessHoursMessage: string
    missedCallWithVoicemail: string
    missedCallWithoutVoicemail: string
    afterHoursMessage: string
    afterHoursMissedCallWithVoicemail: string
    afterHoursMissedCallWithoutVoicemail: string
  }
}

export type RecordingType = 'voicemail' | 'away-voicemail'

export interface Recording {
  id?: string
  local?: boolean
  userId?: string
  name?: string
  url: string
  type?: RecordingType
  default?: boolean
}

export const roleNameSchema = z.enum(['owner', 'admin', 'member'])
export type RoleName = z.infer<typeof roleNameSchema>

export interface Role {
  name: RoleName
  displayName: string
  description: string
}

export interface Invitation {
  email: string
  role: RoleName
  phoneNumberId: string | null
}

export interface SearchPhoneNumberParams {
  ip?: string
  country?: string
  inLocality?: string
  inRegion?: string
  tollFree?: boolean
  areaCode?: string
  latitude?: string
  longitude?: string
  contains?: string
}

export interface UserPhoneNumber {
  id?: string
  local?: boolean
  groupId?: string
  email: string
  role: RoleName
  firstName: string
  lastName: string
  phoneNumberId?: string
}

export const defaultAvailabilityScheduleRange: AvailabilityScheduleRange = {
  start: '0900',
  end: '1700',
}

export const defaultAvailabilitySchedule: NonNullAvailabilitySchedule = [
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
  defaultAvailabilityScheduleRange,
]

export const defaultAvailability: AvailabilityHours = {
  enabled: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  schedule: defaultAvailabilitySchedule,
}

export default class AccountClient {
  admin = {
    phoneNumbers: (): Promise<CodableEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber` }),
      )
    },

    buyPhoneNumber: (
      number: string,
      assignTo?: string,
    ): Promise<CodableEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber`,
          body: { phoneNumber: { number, entityId: assignTo } },
        }),
      )
    },

    updatePhoneNumber: (
      phoneNumber: CodableEntityPhoneNumber,
    ): Promise<CodableEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumber.id}`,
          body: { phoneNumber },
        }),
      )
    },

    updatePhoneNumberSettings: (
      phoneNumberId: string,
      settings: PhoneNumberSettings,
    ): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/settings`,
          body: settings,
        }),
      )
    },

    addPhoneNumberUser: (
      phoneNumberId: string,
      userId: string,
      role: RoleName,
    ): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/user`,
          body: { userId, role },
        }),
      )
    },

    removePhoneNumberUser: (
      phoneNumberId: string,
      userId: string,
    ): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/user`,
          body: { userId },
        }),
      )
    },

    reassignPhoneNumber: (
      phoneNumberId: string,
      ownerId: string,
    ): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/assign`,
          body: { ownerId },
        }),
      )
    },

    toggleInternational: (
      phoneNumberId: string,
      enabled: boolean,
    ): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/international`,
          body: { enabled },
        }),
      )
    },

    deletePhoneNumber: (phoneNumberId: string): Promise<CodableEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}`,
        }),
      )
    },

    entities: (): Promise<CodableEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}admin/entity` }),
      )
    },
  }

  alert = {
    paginated: (
      limit = 100,
    ): Promise<Paginated<DecodableAlert> & { associations: AlertAssociations }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          query: { limit },
        }),
      )
    },

    since: (since: Date): Promise<Paginated<DecodableAlert>> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          query: { since, includeDeleted: true, limit: 200 },
        }),
      )
    },

    read: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/read`,
          body: ids,
        }),
      )
    },

    unread: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/unread`,
          body: ids,
        }),
      )
    },

    open: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/opened`,
          body: ids,
        }),
      )
    },

    unopen: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/unopened`,
          body: ids,
        }),
      )
    },

    delete: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          body: ids,
        }),
      )
    },
  }

  blocklist = {
    list: (): Promise<CodableBlocklist[]> => {
      return this.transport
        .queue<{ blocklist: CodableBlocklist[] }>(
          new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}blocklist` }),
        )
        .then((a) => a.blocklist)
    },

    create: (phoneNumber: string): Promise<CodableBlocklist> => {
      return this.transport
        .queue<{ blocklist: CodableBlocklist }>(
          new HttpTransaction({
            method: 'post',
            url: `${config.ACCOUNT_SERVICE_URL}blocklist`,
            body: { phoneNumber },
          }),
        )
        .then((a) => a.blocklist)
    },

    delete: (id: string): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}blocklist/${id}`,
        }),
      )
    },
  }

  phoneNumbers = {
    list: (): Promise<PhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}phoneNumber` }),
      )
    },

    available: (filters?: SearchPhoneNumberParams): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/available${toQueryString(
            filters,
          )}`,
        }),
      )
    },

    update: (phoneNumber: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}`,
          body: { phoneNumber },
          retry: true,
        }),
      )
    },

    mute: (phoneNumber: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}/mute`,
          body: {
            until: phoneNumber.mutedUntil
              ? new Date(phoneNumber.mutedUntil)
              : new Date(0),
          },
          retry: true,
        }),
      )
    },

    unmute: (phoneNumber: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}/mute`,
          retry: true,
        }),
      )
    },

    buy: (number: string, consentToSMS: boolean): Promise<PhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber`,
          body: { phoneNumber: { number }, temporary: false, consentToSMS },
        }),
      )
    },

    getBlockedCountryCodes: (): Promise<string[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumbers/blocked-country-codes`,
        }),
      )
    },
  }

  invites = {
    get: (token: string): Promise<Invite> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}invite/${token}` }),
      )
    },

    accept: (token: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}invite/${token}/accept`,
        }),
      )
    },

    reject: (token: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}invite/${token}/reject`,
        }),
      )
    },

    list: (): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}invite` }),
      )
    },
  }

  organization = {
    list: (): Promise<CodableOrganization[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}org` }),
      )
    },

    get: (id: string): Promise<CodableOrganization> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}org/${id}` }),
      )
    },

    update: (org: CodableOrganization): Promise<CodableOrganization> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}org/${org.id}`,
          body: org,
        }),
      )
    },

    roles: (): Promise<Role[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}role` }),
      )
    },

    groups: {
      fetch: ({ includeDeleted = false }: { includeDeleted?: boolean }) => {
        return this.transport.queue(
          new HttpTransaction({
            url: `${config.ACCOUNT_SERVICE_URL}group?includeDeleted=${includeDeleted}`,
          }),
        )
      },

      create: (group: CodableGroup) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'put',
            url: `${config.ACCOUNT_SERVICE_URL}group`,
            body: group,
          }),
        )
      },
      update: (group: CodableGroup) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'put',
            url: `${config.ACCOUNT_SERVICE_URL}group/${group.id}`,
            body: group,
          }),
        )
      },

      delete: (id: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'delete',
            url: `${config.ACCOUNT_SERVICE_URL}group/${id}`,
          }),
        )
      },
      addUser: (groupId: string, userId: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'post',
            url: `${config.ACCOUNT_SERVICE_URL}group/${groupId}/user`,
            body: { userId, role: 'member' },
          }),
        )
      },
      deleteUser: (groupId: string, userId: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'delete',
            url: `${config.ACCOUNT_SERVICE_URL}group/${groupId}/user/${userId}`,
          }),
        )
      },
    },

    for: (orgId: string) => ({
      invites: {
        list: (): Promise<Invite[]> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite`,
            }),
          )
        },

        get: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}`,
            }),
          )
        },

        update: (
          id: string,
          body: { role: RoleName; phoneNumberId?: string },
        ): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'put',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${id}`,
              body,
            }),
          )
        },

        delete: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}`,
            }),
          )
        },

        send: (params: Invitation): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite`,
              body: params,
            }),
          )
        },

        sendBulk: (params: Invitation[]): Promise<{ results: SerializedMember[] }> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/bulk`,
              body: { items: params },
            }),
          )
        },

        resend: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}/resend`,
            }),
          )
        },
      },

      domain: {
        add: (params: { verificationEmail: string }) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain`,
              body: params,
            }),
          )
        },

        fetch: (): Promise<{ results: CodableDomain[] }> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain`,
            }),
          )
        },

        delete: (domainId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/${domainId}`,
            }),
          )
        },

        verify: (params: { email: string; code: string }) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/verify`,
              body: params,
            }),
          )
        },

        resendCode: (domainId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/${domainId}/resendCode`,
            }),
          )
        },
      },

      presence: {
        list: (): Promise<CodablePresence[]> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/presence`,
            }),
          )
        },
      },

      member: {
        fetch: () => {
          return this.transport.queue(
            new HttpTransaction<MemberModel[]>({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/members`,
            }),
          )
        },

        delete: (memberId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/member/${memberId}`,
            }),
          )
        },
      },
    }),
  }

  members = {
    list: (): Promise<SerializedMember[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}member` }),
      )
    },

    get: (id: string): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}member/${id}` }),
      )
    },

    put: (
      id: string,
      params: { role: RoleName; title?: string },
    ): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}member/${id}`,
          body: params,
        }),
      )
    },

    delete: (id: string): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}member/${id}`,
        }),
      )
    },
  }

  presence = {
    get: (): Promise<CodablePresence[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}presence`,
        }),
      )
    },

    put: (presence: Partial<CodablePresence>): Promise<CodablePresence> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}presence`,
          method: 'put',
          body: {
            offlineUntil: presence.offlineUntil,
            status: presence.status,
            symbol: presence.symbol,
            text: presence.text,
          },
        }),
      )
    },

    doNotDisturb: (duration: number): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}availability`,
          method: 'post',
          body: { duration },
        }),
      )
    },

    clearDoNotDisturb: (): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}availability`,
          method: 'delete',
        }),
      )
    },
  }

  userSettings = {
    update: (settings: CodableUserSettings) => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}userSettings`,
          method: 'put',
          body: settings,
        }),
      )
    },
  }

  recording = {
    fetch: (): Promise<Recording[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}recording`,
        }),
      )
    },

    create: (recording: Partial<Recording>): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}recording`,
          body: recording,
        }),
      )
    },

    update: (recording: Recording): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}recording/${recording.id}`,
          body: recording,
        }),
      )
    },

    delete: (recording: Recording): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}recording/${recording.id}`,
        }),
      )
    },
  }

  application = {
    get: (clientId: string, redirectUrl: string): Promise<Application> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}application`,
          query: { client_id: clientId, redirect_url: redirectUrl },
        }),
      )
    },
  }

  referrals = {
    getCode: (): Promise<ReferralCode[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral`,
        }),
      )
    },

    getAcceptedReferrals: (): Promise<AcceptedReferral[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral/accepted`,
        }),
      )
    },

    accept: (code: string): Promise<AcceptedReferral> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral/${code}/accept`,
          method: 'post',
        }),
      )
    },
  }

  workspace = {
    phoneNumbers: {
      list: (): Promise<CodablePhoneNumber[]> => {
        return this.transport.queue(
          new HttpTransaction({
            url: `${config.ACCOUNT_SERVICE_URL}workspace/phoneNumbers`,
          }),
        )
      },
    },
  }

  connections = {
    list: (): Promise<AccountConnectionsResult> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}account/connections` }),
      )
    },
  }

  portRequest = {
    getAll: (): Promise<AccountPortRequestGetAllResponse> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}portRequest` }),
      )
    },
    upsert: (
      dto: AccountPortRequestUpsertRequest,
    ): Promise<AccountPortRequestUpsertResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}portRequest`,
          method: 'post',
          body: dto,
        }),
      )
    },
    delete: (id: string): Promise<unknown> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}portRequest/${id}`,
          method: 'delete',
        }),
      )
    },
    submit: ({ id, ...dto }: AccountPortRequestSubmitRequest): Promise<unknown> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}portRequest/${id}/submit`,
          method: 'put',
          body: dto,
        }),
      )
    },
    resubmit: (id: string): Promise<unknown> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}portRequest/${id}/resubmit`,
          method: 'put',
        }),
      )
    },
  }

  domain = {
    validate: (email: string): Promise<{ valid: boolean }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}/domain/validate/${email}`,
        }),
      )
    },
  }

  constructor(private transport: Transport) {}

  get = (): Promise<EncodableUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account`,
      }),
    )
  }

  update = (user: Partial<CodableUser>): Promise<EncodableUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account`,
        method: 'put',
        body: user,
        queue: user.id,
      }),
    )
  }

  verifyPhoneNumber = (phoneNumber: string, recaptcha_token?: string): Promise<void> => {
    const headers = recaptcha_token ? { 'x-op-recaptcha': recaptcha_token } : undefined
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/verify`,
        method: 'post',
        body: { phoneNumber },
        retry: false,
        headers,
      }),
    )
  }

  checkPhoneNumberCode = (phoneNumber: string, code: string): Promise<EncodableUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/verify/check`,
        method: 'post',
        body: { phoneNumber, code },
        retry: false,
      }),
    )
  }

  sendDownloadLink = (phoneNumber: string): Promise<any> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/sendDownloadLink`,
        method: 'post',
        body: { phoneNumber },
        retry: false,
      }),
    )
  }

  associate = (data: {
    partnerKey: string
    firstName: string
    lastName: string
    email: string
  }) => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/associate`,
        method: 'post',
        body: data,
        retry: true,
      }),
    )
  }
}
